<template>
  <SelectorProductModalDesktop
    :is-open="isMounted && showModal"
    :type-of-variant="''"
    :is-coming-soon="false"
    :product-uri="productUri">
    <div class="modal-actions-go_to_pdp">
      <a
        class="modal-actions-go_to_pdp--text"
        :href="productUri"
        @click="sendProductClick">
        {{ $t('global.show_detail') }}
      </a>
    </div>
  </SelectorProductModalDesktop>
</template>

<script>
import UtilsAnalytics from 'CommonUtils/analytics';
import SelectorProductModalDesktop from './SelectorProductModalDesktop.vue';
import { ref, onMounted } from 'vue';

export default {
  name: 'SelectorGoToPDP',
  components: {
    SelectorProductModalDesktop,
  },
  props: {
    productUri: {
      type: String,
      required: true,
    },
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const crossSellingType = inject('crossSellingType');
    const getDataLayer = inject('getDataLayer');
    const isRichRelevance = inject('isRichRelevance');
    const isMounted = ref(false);

    const sendProductClick = () => {
      UtilsAnalytics.sendProductPreviewLinkToPDPClick(getDataLayer(), crossSellingType, isRichRelevance);
    };

    onMounted(() => {
      isMounted.value = true;
    });

    return {
      isMounted,
      sendProductClick,
    };
  },
};
</script>
